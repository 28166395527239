import axios from '@axios'

/* eslint-disable import/prefer-default-export */
export const fetchUsersList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/users/', { params })
  return res
}

export const fetchUser = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/users/${id}`)
  return res
}
export const exportUsers = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/users/export', { params })
  return res
}

export const loginAsUser = async (params?: any): Promise<any> => {
  const res = await axios.post('/auth/login-as-user', { user_id: `${params}` })
  return res
}

export const deleteUser = async (id: string): Promise<any> => {
  const res = await axios.delete(`/auth/users/${id}`)
  return res
}
