import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import store from '@/store'
import {
  FamiliesMergerState,
  IProfileFamilyState,
  IProfileState,
  IProfileUserState,
} from './types'
import { mergeProfiles } from '@/api/families'

// eslint-disable-next-line import/prefer-default-export
export const appFamiliesMerger = createModule(
  'appFamiliesMerger',
  {
    namespaced: true,
    state: {
      firstProfile: {
        type: null,
        user_id: null,
        family_id: null,
        is_major: false,
        user: null,
        family: null,
      } as IProfileState,
      secondProfile: {
        type: null,
        user_id: null,
        family_id: null,
        is_major: false,
        user: null,
        family: null,
      } as IProfileState,
      profilesTypes: [
        'User',
        'Family',
      ],
      isLoading: false,
      isMerging: false,
      validationErrors: null,
      successfulMessages: null,
    } as FamiliesMergerState,
    getters: {
      getIsLoading: state => state.isLoading,
      getIsMerging: state => state.isMerging,
      getFirstProfile: state => state.firstProfile,
      getSecondProfile: state => state.secondProfile,
      getProfilesTypes: state => state.profilesTypes,
      getValidationErrors: state => state.validationErrors,
      getSuccessfulMessages: state => state.successfulMessages,
    },
    mutations: {
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_IS_MERGING(state, val: boolean) {
        state.isMerging = val
      },
      SET_FIRST_PROFILE(state, val: any) {
        state.firstProfile = val
      },
      SET_FIRST_PROFILE_USER(state, val: IProfileUserState) {
        state.firstProfile.user = val
      },
      SET_FIRST_PROFILE_FAMILY(state, val: IProfileFamilyState) {
        state.firstProfile.family = val
      },
      SET_SECOND_PROFILE(state, val: any) {
        state.secondProfile = val
      },
      SET_SECOND_PROFILE_USER(state, val: IProfileUserState) {
        state.secondProfile.user = val
      },
      SET_SECOND_PROFILE_FAMILY(state, val: IProfileFamilyState) {
        state.secondProfile.family = val
      },
      SET_PROFILES_TYPES(state, val: [string, string]) {
        state.profilesTypes = val
      },
      SET_VALIDATIONS_ERRORS(state, val: string | null) {
        state.validationErrors = val
      },
      SET_SUCCESSFUL_MESSAGES(state, val: string | null) {
        state.successfulMessages = val
      },
      RESET_DATA(state) {
        state.firstProfile.type = null
        state.firstProfile.user_id = null
        state.firstProfile.family_id = null
        state.firstProfile.is_major = false

        state.secondProfile.type = null
        state.secondProfile.user_id = null
        state.secondProfile.family_id = null
        state.secondProfile.is_major = false

        state.validationErrors = null
        state.successfulMessages = null
      },
    },
    actions: {
      async mergeProfiles(ctx, data) {
        try {
          await mergeProfiles(data)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success merging profiles',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error merging profiles',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appFamiliesMerger.register(store)
