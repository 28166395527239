
























































































































































































































































































































































































































































import Vue from 'vue'
import {
  BCard,
  BCardHeader,
  BCol,
  BRow,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { appFamiliesMerger } from '@/store/modules/app-families/app-families-merger'
import { appFamiliesList } from '@/store/modules/app-families/app-families-list'
import { appUsersList } from '@/store/modules/app-users/app-users-list'
import { permissionSubjects } from '@/libs/acl/constants'
import UserProfileDetails from '@/views/admin/families/families-merger/UserProfileDetails.vue'
import FamilyProfileDetails from '@/views/admin/families/families-merger/FamilyProfileDetails.vue'
import { IProfileState } from '@/store/modules/app-families/app-families-merger/types'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'FamiliesMerger',
  components: {
    UserProfileDetails,
    FamilyProfileDetails,
    BCardTitle,
    BCard,
    BCardHeader,
    BCol,
    BRow,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BOverlay,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  computed: {
    profilesTypes() {
      return appFamiliesMerger.getters.getProfilesTypes
    },
    isLoading: {
      get(): boolean {
        return appFamiliesMerger.getters.getIsLoading
      },
      set(val: boolean) {
        appFamiliesMerger.mutations.SET_IS_LOADING(val)
      },
    },
    isMerging: {
      get(): boolean {
        return appFamiliesMerger.getters.getIsMerging
      },
      set(val: boolean) {
        appFamiliesMerger.mutations.SET_IS_MERGING(val)
      },
    },
    firstProfile: {
      get(): IProfileState {
        return appFamiliesMerger.getters.getFirstProfile
      },
      set(val: IProfileState) {
        appFamiliesMerger.mutations.SET_FIRST_PROFILE(val)
      },
    },
    secondProfile: {
      get(): IProfileState {
        return appFamiliesMerger.getters.getSecondProfile
      },
      set(val: IProfileState) {
        appFamiliesMerger.mutations.SET_SECOND_PROFILE(val)
      },
    },
    validationErrors: {
      get(): string | null {
        return appFamiliesMerger.getters.getValidationErrors
      },
      set(val: string | null) {
        appFamiliesMerger.mutations.SET_VALIDATIONS_ERRORS(val)
      },
    },
    successfulMessages: {
      get(): string | null {
        return appFamiliesMerger.getters.getSuccessfulMessages
      },
      set(val: string | null) {
        appFamiliesMerger.mutations.SET_SUCCESSFUL_MESSAGES(val)
      },
    },
    familiesCurrentPage: {
      get(): number {
        return appFamiliesList.getters.getCurrentPage
      },
      set(val: number) {
        appFamiliesList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    familiesSearchQuery: {
      get(): any {
        return appFamiliesList.getters.getSearchQuery
      },
      set(val: any) {
        appFamiliesList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    familiesSortBy: {
      get(): string {
        return appFamiliesList.getters.getSortBy
      },
      set(val: string) {
        appFamiliesList.mutations.SET_SORT_BY(val)
      },
    },
    familiesIsSortDirDesc: {
      get() {
        return appFamiliesList.getters.getIsSortDirDesc
      },
      set(val: boolean) {
        appFamiliesList.mutations.SET_IS_SORT_DESC(val)
      },
    },
    familiesDataMeta() {
      return appFamiliesList.getters.getDataMeta
    },
    familiesItems() {
      return appFamiliesList.getters.getItems
    },
    familiesHasNextPage() {
      return appFamiliesList.getters.getHasNextPage
    },
    familiesHasPrevPage() {
      return appFamiliesList.getters.getPrevPage
    },
    usersCurrentPage: {
      get(): number {
        return appUsersList.getters.getCurrentPage
      },
      set(val: number) {
        appUsersList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    usersSearchQuery: {
      get(): any {
        return appUsersList.getters.getSearchQuery
      },
      set(val: any) {
        appUsersList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    usersSortBy: {
      get(): string {
        return appUsersList.getters.getSortBy
      },
      set(val: string) {
        appUsersList.mutations.SET_SORT_BY(val)
      },
    },
    usersIsSortDirDesc: {
      get(): boolean {
        return appUsersList.getters.getIsSortDirDesc
      },
      set(val: boolean) {
        appUsersList.mutations.SET_IS_SORT_DESC(val)
      },
    },
    usersDataMeta() {
      return appUsersList.getters.getDataMeta
    },
    usersItems() {
      return appUsersList.getters.getItems
    },
    usersHasNextPage() {
      return appUsersList.getters.getHasNextPage
    },
    usersHasPrevPage() {
      return appUsersList.getters.getPrevPage
    },
  },
  watch: {
    firstProfile: {
      handler() {
        this.successfulMessages = null
      },
      deep: true,
      immediate: true,
    },
    secondProfile: {
      handler() {
        this.successfulMessages = null
      },
      deep: true,
      immediate: true,
    },
    'firstProfile.is_major': function fn(val) {
      if (val) {
        this.secondProfile.is_major = false
      }
    },
    'secondProfile.is_major': function fn(val) {
      if (val) {
        this.firstProfile.is_major = false
      }
    },
    familiesCurrentPage(val, prevVal) {
      if (val !== prevVal) {
        this.fetchFamiliesList()
      }
    },
    familiesSearchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          this.fetchFamiliesList()
        }, 250)
      }
    },
    usersCurrentPage(val, prevVal) {
      if (val !== prevVal) {
        this.fetchUsersList()
      }
    },
    usersSearchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          this.fetchUsersList()
        }, 250)
      }
    },
  },
  async created() {
    appFamiliesMerger.mutations.RESET_DATA()
    await this.fetchFamiliesList()
    await this.fetchUsersList()
  },
  methods: {
    async onFamilySearch(query: any) {
      this.$nextTick(() => {
        this.familiesSearchQuery = query
        this.familiesCurrentPage = 1
      })
    },
    async onUsersSearch(query: any) {
      this.$nextTick(() => {
        this.usersSearchQuery = query
        this.usersCurrentPage = 1
      })
    },
    async fetchFamiliesList() {
      this.isLoading = true
      this.familiesSortBy = 'familyName'
      this.familiesIsSortDirDesc = false
      await appFamiliesList.actions.fetchFamiliesList()
      this.isLoading = false
    },
    async fetchUsersList() {
      this.isLoading = true
      this.usersSortBy = 'last_name'
      this.usersIsSortDirDesc = false
      await appUsersList.actions.fetchAllUsersList()
      this.isLoading = false
    },
    async getUser(id: string|number) {
      return appUsersList.actions.fetchUser(id)
    },
    async onUserFirstProfileSelect(id: string|number) {
      appFamiliesMerger.mutations.SET_FIRST_PROFILE_USER(await this.getUser(id))
    },
    async onUserSecondProfileSelect(id: string|number) {
      appFamiliesMerger.mutations.SET_SECOND_PROFILE_USER(await this.getUser(id))
    },
    async getFamily(id: string|number) {
      return appFamiliesList.actions.fetchFamily(id)
    },
    async onFamilyFirstProfileSelect(id: string|number) {
      appFamiliesMerger.mutations.SET_FIRST_PROFILE_FAMILY(await this.getFamily(id))
    },
    async onFamilySecondProfileSelect(id: string|number) {
      appFamiliesMerger.mutations.SET_SECOND_PROFILE_FAMILY(await this.getFamily(id))
    },
    async validate() {
      this.validationErrors = null

      // Ensure one primary profile is selected
      if (!this.firstProfile.is_major && !this.secondProfile.is_major) {
        this.validationErrors = 'Please select the primary profile.'
        return false
      }

      if (this.firstProfile.is_major && this.secondProfile.is_major) {
        this.validationErrors = 'Only one primary profile can be selected.'
        return false
      }

      // Determine major and minor profiles
      const [majorProfile, minorProfile]: [IProfileState, IProfileState] = this.firstProfile.is_major
        ? [this.firstProfile, this.secondProfile]
        : [this.secondProfile, this.firstProfile]

      if (majorProfile === null || minorProfile === null) {
        this.validationErrors = 'Something went wrong.'
        return false
      }

      // Validate family and user merging logic
      if (majorProfile.type === 'User' && minorProfile.type === 'Family') {
        this.validationErrors = 'A family cannot be merged into a user. '
          + 'Only a user can be merged into a family. '
          + 'Please select the family as the major profile or choose a different strategy.'

        return false
      }

      // Validate family-family merge
      if (majorProfile.type === 'Family' && minorProfile.type === 'Family') {
        if (majorProfile.family_id === minorProfile.family_id) {
          this.validationErrors = 'The families cannot be the same.'
          return false
        }
      }

      // Validate user-user merge
      if (majorProfile.type === 'User' && minorProfile.type === 'User') {
        if (minorProfile.user === null || majorProfile.user === null) {
          this.validationErrors = 'The user doesn\'t exist.'
          return false
        }

        if (minorProfile.user.role === null || majorProfile.user.role === null) {
          this.validationErrors = 'The role doesn\'t exist.'
          return false
        }

        if (majorProfile.user_id === minorProfile.user_id) {
          this.validationErrors = 'The users cannot be the same.'
          return false
        }

        if (majorProfile.user.role.name !== minorProfile.user.role.name) {
          this.validationErrors = 'The users must have the same roles.'
          return false
        }

        // Validate if merging would leave children without parents
        if (minorProfile.user.role.name === 'parent'
          && minorProfile.user.family_parents === 1
          && minorProfile.user.family_children > 0
        ) {
          this.validationErrors = 'This merge is not possible because it would leave children without parents. Consider merging families instead.'
          return false
        }
      }

      return true
    },
    async mergeProfiles() {
      this.isMerging = true
      const validated = await this.validate()

      if (!validated) {
        this.isMerging = false
        return
      }

      await appFamiliesMerger.actions.mergeProfiles({
        firstProfile: this.firstProfile,
        secondProfile: this.secondProfile,
      })

      this.isMerging = false
      this.successfulMessages = 'The merger was successful.'
    },
  },
})
