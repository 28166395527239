import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { UsersListState, IUserListRequest } from './types'
import {
  deleteUser,
  fetchUsersList,
  exportUsers,
  fetchUser,
} from '@/api/users'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'
import downloadFile from '@/helpers/downloadExportFile'

// eslint-disable-next-line import/prefer-default-export
export const appUsersList = createModule(
  'appUsersList',
  {
    namespaced: true,
    state: {
      tableColumns: [],
      perPage: Number(localStorage.getItem('allUsersPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '' as string | (string | null)[],
      sortBy: localStorage.getItem('allUsersSortBy') ? JSON.parse(localStorage.getItem('allUsersSortBy')!) : 'registration_date',
      isSortDirDesc: localStorage.getItem('allUsersIsSortDesc') ? (JSON.parse(localStorage.getItem('allUsersIsSortDesc')!) === true) : false,
      filterDataRole: '',
      filterRoleOptions: ['Parent', 'Staff', 'Child'],
      isLoading: false,
      items: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        current_page: null,
        last_page: null,
      },
    } as UsersListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getVisibleTableColumns: state => state.tableColumns.filter(item => item.selected),
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getFilterDataRole: state => state.filterDataRole,
      getFilterRoleOptions: state => state.filterRoleOptions,
      getIsLoading: state => state.isLoading,
      getDataMeta: state => state.dataMeta,
      getItems: state => state.items,
      getHasNextPage: state => state.dataMeta.current_page !== state.dataMeta.last_page,
      getPrevPage: state => state.dataMeta.current_page !== null && state.dataMeta.current_page > 1,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('allUsersPerPage', val.toString())
        state.perPage = val
      },
      SET_TABLE_COLUMNS: (state, val) => {
        state.tableColumns = val
        localStorage.setItem('allUsersTableColumns', JSON.stringify(val))
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
        localStorage.setItem('allUsersSortBy', JSON.stringify(val))
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
        localStorage.setItem('allUsersIsSortDesc', JSON.stringify(val))
      },
      SET_FILTER_DATA_ROLE(state, val: any) {
        state.filterDataRole = val
      },
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      SET_ITEMS(state, val) {
        state.items = val
      },
      CLEAR_FILTERS(state) {
        state.filterDataRole = ''
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('allUsersPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
        state.filterDataRole = query.filterDataRole || ''
      },
    },
    actions: {
      async fetchAllUsersList({ state }) {
        try {
          let sortBy = String(state.sortBy)
          if (sortBy === 'registration_date') {
            sortBy = 'created_at'
          }
          const queryParams: IUserListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy,
            sortDesc: state.isSortDirDesc,
            withoutAdminRoles: true,
          }
          if (state.searchQuery) queryParams.search = state.searchQuery
          if (state.filterDataRole) queryParams.role = state.filterDataRole

          const response: any = await fetchUsersList(queryParams)

          const {
            last_page, current_page, total, from, to,
          } = response.data.meta

          const dataMeta = {
            current_page,
            last_page,
            from,
            to,
            of: total,
          }

          appUsersList.mutations.SET_TOTAL_ITEMS(total)
          appUsersList.mutations.SET_DATA_META(dataMeta)
          appUsersList.mutations.SET_ITEMS(response.data.data)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async fetchUser({ state }, userId) {
        try {
          const response: any = await fetchUser(userId)

          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async deleteUser(ctx, id) {
        try {
          await deleteUser(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success deleting User',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting User',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async exportUsersCSV({ state }) {
        try {
          appUsersList.mutations.SET_IS_LOADING(true)

          const queryParams: IUserListRequest = {
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
            withoutAdminRoles: true,
          }
          if (state.searchQuery) queryParams.name = state.searchQuery
          if (state.filterDataRole) queryParams.role = state.filterDataRole

          const response = await exportUsers(queryParams)
          if (response.status === 200) {
            downloadFile(response.data, 'Users', 'csv')
          }
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting users',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appUsersList.mutations.SET_IS_LOADING(false)
        }
      },
    },
  },
)

appUsersList.register(store)
