<template>
  <b-card
    no-body
    :class="{'open': visible}"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <span class="lead collapse-title">User Detailed Data</span>
      </slot>
      <feather-icon
        :icon="visible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        size="16"
        class="align-middle text-body"
      />
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-table-simple
            borderless
          >
            <b-tbody>
              <b-tr>
                <b-td> <span class="font-weight-bold">ID</span></b-td>
                <b-td class="text-right">
                  {{ user.id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td><span class="font-weight-bold">Full Name</span></b-td>
                <b-td class="text-right">
                  <b-link
                    v-if="['parent', 'staff', 'child'].includes(user.role.name)"
                    :to="findRoutByUserRole(user)"
                  >{{ user.full_name }}
                  </b-link>
                  <span v-else>{{ user.full_name }}</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">First Name</span></b-td>
                <b-td class="text-right">
                  {{ user.first_name }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Last Name</span></b-td>
                <b-td class="text-right">
                  {{ user.last_name }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Email</span></b-td>
                <b-td class="text-right">
                  {{ user.email }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Phone</span></b-td>
                <b-td class="text-right">
                  {{ user.phone }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Role</span></b-td>
                <b-td class="text-right">
                  {{ user.role.display_name }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Last Login</span></b-td>
                <b-td class="text-right">
                  {{ user.last_login }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Registration Date</span></b-td>
                <b-td class="text-right">
                  {{ user.registration_date }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Family Id</span></b-td>
                <b-td class="text-right">
                  {{ user.family_id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Family Name</span></b-td>
                <b-td class="text-right">
                  <b-link
                    :to="{ name: 'admin-family-view', params: { id: user.family_id } }"
                  >{{ user.family_name }}</b-link>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Family Member Id</span></b-td>
                <b-td class="text-right">
                  {{ user.family_member_id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Family Member Is Primary</span></b-td>
                <b-td class="text-right">
                  {{ user.family_member_is_primary }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Family Member Relationship</span></b-td>
                <b-td class="text-right">
                  {{ user.family_member_relationship }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Total Children in Family</span></b-td>
                <b-td class="text-right">
                  {{ user.family_children }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td> <span class="font-weight-bold">Total Parents in Family</span></b-td>
                <b-td class="text-right">
                  {{ user.family_parents }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCol,
  BRow,
  BCollapse,
  BTableSimple,
  BTbody,
  BTd,
  BTr,
  BLink,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'UserProfileDetails',
  components: {
    BLink,
    BTbody,
    BTableSimple,
    BTd,
    BTr,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCollapse,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visible: true,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    findRoutByUserRole(item) {
      if (item.role.name === 'parent') {
        return { name: 'admin-parent-profile', params: { id: item.user_parent_id } }
      }
      if (item.role.name === 'staff') {
        return { name: 'admin-staff-profile', params: { id: item.id } }
      }
      if (item.role.name === 'child') {
        return { name: 'admin-student-profile', params: { id: item.id } }
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.card-header {
  position: relative;
  background: #fff;
}

.collapse-icon [data-toggle=collapse]:after {
  content: "";
  position: absolute;
  right: 1rem;
  top: 58%;
  width: 14px;
  height: 11px;
  transition: all .3s linear 0s;
  margin-top: -8px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 14px;
}

.collapse-icon [aria-expanded=false]:after {
  transform: rotate(-180deg);
}
.collapse-header {
  font-weight: 400;
  font-size: 20px;
  &__img {
    width: 18px;
  }
}

</style>
